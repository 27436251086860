<template>
  <div class="frame">
    <v-subtitle
      zh="功能框架"
      en="Functional framework"
      type="mini"
    />
    <div class="frame-img">
      <img
        :src="frameimg"
      >
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
export default {
  components: {
    vSubtitle
  },
  props: {
    frameimg: {
      type: String,
      default: '/static/img/productCenter/a-3.png'
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.frame{
  margin-bottom: 80px;
  padding-top: 20px;
  &-img{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      flex: 1;
      width: 100%;
    }
  }
}
</style>
