<template>
  <div
    class="technology"
    id="technology"
  >
    <v-subtitle
      zh="技术架构"
      en="The technical architecture"
      type="mini"
    />
    <div class="technology-img">
      <img :src="technology">
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
export default {
  components: {
    vSubtitle
  },
  props: {
    technology: {
      type: String,
      default: '/static/img/productCenter/a-4.png'
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.technology {
  margin-bottom: 80px;
  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
