<template>
  <div class="product-advantage">
    <v-subtitle
      zh="产品特点"
      en="Product advantage"
      type="mini"
    />
    <div class="characteristic">
      <v-characteristic
        v-for="(item, index) in typeslist1"
        :key="index"
        :data="item"
      />
    </div>
    <div class="characteristic">
      <v-characteristic
        v-for="(item, index) in typeslist2"
        :key="index"
        :data="item"
      />
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vCharacteristic from './characteristic.vue'
export default {
  components: {
    vSubtitle,
    vCharacteristic
  },
  props: {
    typeslist1: {
      type: Array,
      default: () => [
        {
          img: '/static/img/productCenter/b-1.png',
          title: '方便灵活',
          content: '主流程及主流程节点下的子流程支持配置，方便灵活'
        },
        {
          img: '/static/img/productCenter/b-2.png',
          title: '适应多种业务',
          content:
            '系统预置了符合相关法律法规要求的业务流程，可根据业主单位特殊需求进行定制'
        },
        {
          img: '/static/img/productCenter/b-3.png',
          title: '移动端',
          content: '移动端可查看项目及进行简单操作，方便用户随时关注'
        }
      ]
    },
    typeslist2: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.product-advantage {
  margin-bottom: 80px;
  padding-bottom: 20px;
}
.characteristic {
  padding-top: 60px;
  display: flex;
  justify-content: center;
}
</style>
