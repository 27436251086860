<template>
  <div class="characteristic-item">
    <div class="characteristic-img">
      <img
        :src="data.img"
        :alt="data.title"
      >
    </div>
    <div class="characteristic-title">
      {{ data.title }}
    </div>
    <div class="characteristic-content">
      {{ data.content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {
          img: '',
          title: '',
          content: ''
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.characteristic {
  &-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 200px;
    }
  }
  &-img {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
    img {
      width: 100%;
      max-height: 70px;
    }
  }
  &-title {
    font-size: 20px;
    color: #000000;
    margin-bottom: 18px;
  }
  &-content {
    font-size: 14px;
    color: #6D7281;
    text-align: center;
    width: 200px;
  }
}
</style>
