<template>
  <div
    class="industry"
    id="industry"
  >
    <v-subtitle
      zh="解决行业痛点"
      en="Industry pain points"
      type="mini"
    />
    <div class="pain-points">
      <v-pain-points
        v-for="(item, index) in painPoints"
        :key="index"
        :data="item"
      />
    </div>
    <VSuspensionImg
      position="right"
      img="/static/img/suspendedBlock/a-2.png"
      :img-style="{ transform: 'translate(50%, 50%)', bottom: 0, 'z-index': 8 }"
    />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vPainPoints from './painPoints.vue'
export default {
  components: {
    vSubtitle,
    vPainPoints
  },
  props: {
    painPoints: {
      type: Array,
      default: () => [
        {
          src: '/static/img/productCenter/a-1.png',
          content: '打通项目信息发布的通道自动发布相关信息；'
        },
        {
          src: '/static/img/productCenter/a-2.png',
          content: '公开信息触达每一个招投标参与方，解决合规性风险。'
        }
      ]
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.industry {
  position: relative;
  padding-bottom: 80px;
  padding-top: 50px;
}
.pain-points {
  display: flex;
  justify-content: center;
}
</style>
