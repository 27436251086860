<template>
  <div class="pain-points-item">
    <div class="pain-points-img">
      <img
        :src="data.src"
        :alt="data.content"
      >
    </div>
    <div class="pain-points-content">
      {{ data.content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {
          src: '',
          content: ''
        }
      }
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.pain-points {
  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    box-sizing: border-box;
    width: 320px;
    height: 260px;
    background: linear-gradient(180deg, #e5f2fd 0%, #fefefe 100%);
    box-shadow: 0px 3px 6px 1px rgba(73, 120, 250, 0.19);
    border: 3px solid #ffffff;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
    &:hover{
        box-shadow: 0px 2px 2px 0px rgba(73, 120, 250, 0.19);
        border: 3px solid #e5f2fd;
    }
    &:not(:last-child){
        margin-right: 120px;
    }
  }
  &-img {
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    img {
      max-height: 120px;
      width: 100%;
    }
  }
  &-content {
    width: 150px;
    font-size: 14px;
    color: #6d7281;
    text-align: center;
  }
}
</style>
