<template>
  <!-- 新闻轮播图 -->
  <div
    class="banner-box banner100vw"
    v-loading="loading"
    v-if="data.length > 1"
  >
    <div class="banner-box-swiper">
      <swiper
        :options="swiperOptions"
        @click-slide="handleClickSlide"
        v-if="!loading"
      >
        <swiper-slide
          v-for="(item, index) in data"
          :key="index"
        >
          <v-banner :data="item" />
        </swiper-slide>
        <!-- 条 -->
        <template #pagination>
          <div class="swiper-pagination" />
        </template>
      </swiper>
    </div>
  </div>
  <v-banner100vw
    :data="data[0]"
    v-else
  >
    <template slot="button">
      <slot name="button" />
    </template>
  </v-banner100vw>
</template>

<script>
import vBanner from '@/components/banner/index.vue'
import vBanner100vw from '@/components/banner/100vw.vue'
export default {
  components: {
    vBanner,
    vBanner100vw
  },
  props: {
    data: {
      type: Array,
      default: () => [
        {
          id: '',
          title: '',
          content: '',
          imgUrl: ''
        }
      ]
    },
    interval: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      loading: false,
      // #region 轮播图配置
      swiperOptions: {
        autoplay: {
          delay: this.interval
        },
        speed: 1000,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
      // #endregion
    }
  },
  methods: {
    /**
     * 点击轮播图
     */
    handleClickSlide (index, reallyIndex) {
      const item = this.swiperData[reallyIndex]
      console.log('当前点击=>1', item)
    }
  }
}
</script>

<style lang="less" scoped>
// 单个轮播图
.banner-one {
  margin-bottom: 50px;
  &::before {
    background: linear-gradient(180deg, #ffffff 0%, #f7f9fc 100%) !important;
  }
}
.banner100vw();
// 多张轮播图
.banner-box {
  width: 100%;
  height: 400px;
  &-swiper {
    height: 100%;
    width: 100%;
  }
  /deep/ .swiper-container {
    height: 100%;
    .swiper-pagination {
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
    .swiper-pagination-bullet {
      border-radius: 3px;
      height: 5px;
      width: 40px;
      background: #fff;
      opacity: 1;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .swiper-pagination-bullet-active {
      background: @mainColor;
    }
  }
}
</style>
