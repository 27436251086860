<template>
  <div
    class="customer-stories"
    id="stories"
  >
    <v-subtitle
      zh="应用案例"
      en="Solution overview"
      type="mini"
    />
    <div class="case-list">
      <v-case
        v-for="(item, index) in caseList"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vCase from './case.vue'
export default {
  components: {
    vSubtitle,
    vCase
  },
  props: {
    caseList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return { }
  }
}
</script>

<style lang="less" scoped>
.customer-stories{
  .case-list{
    display: flex;
    justify-content: center;
  }
}
</style>
